<template>
	<div class="sld_chat_page">
		<chatLeftList :showAdmin="false" @switchMember="chatInit" ref="left" :connectBaseData="connectBaseData" />
		<div class="chat_zone">
			<!-- 聊天界面框start -->
			<chatWindow ref="chatWindow" hasFilter></chatWindow>
			<!-- 聊天界面框end -->
		</div>
	</div>
</template>
<script>
import 'element-plus/dist/index.full.js';
import chatWindow from '@/components/chatWindow';
import { ref, reactive, getCurrentInstance, onMounted, watch, computed } from 'vue';
import chatLeftList from './chatPlatform/chatLeftList';
import { ElMessage } from 'element-plus';
export default {
	name: 'chatHistory',
	components: {
		chatWindow,
		chatLeftList,
	},
	setup() {
		const connectBaseData = {
			storeId: localStorage.identity == 'seller' ? localStorage.storeId : 0,
			userId: localStorage.identity == 'seller' ? localStorage.vendorId : localStorage.adminId,
			role: 2,
		};
		const { proxy } = getCurrentInstance();
		const L = proxy.$getCurLanguage();
		const chatInit = (item) => {
			let window_instance = proxy.$refs.chatWindow;
			let { memberId, storeId, vendorId, memberName } = item;
			window_instance.setId({memberId, vendorId, storeId, memberName});
		};
		onMounted(() => {});

		return {
			chatInit,
			connectBaseData
		};
	},
};
</script>
<style lang="scss" scoped>
.sld_chat_page {
	flex-shrink: 0;
	width: 100%;
	height: 810px;
	display: flex;
	flex-shrink: 0;

	.chat_zone {
		flex: 1;
		height: 100%;
	}
}
</style>
